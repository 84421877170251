<template>
  <div class="lottery-history">
    <div class="header">
      <div class="title-box">
        <p class="title">历史开奖</p>
        <intro-default cont=""></intro-default>
      </div>
      <div class="search-box">
        <select-user type="0" @change="page_change"></select-user>
      </div>
    </div>
    <div class="lz_cont">
      <div id="w_box"></div>
      <ul v-if="sele_list['p'] && sele_list['p'].length > 0" class="types">
        <li v-for="(option, i) in sele_list['p']" :key="i" :class="{ active: typeIndex == i }" @click="typeIndex = i">{{
          option }}</li>
        <li class="types_empty"></li>
      </ul>
      <transition v-show="is_number">
        <div v-show="is_number" class="tool">
          <div class="tool_sele_btns">
            <span class="tool_sele_span">选择号码:</span>
            <div v-for="(item, key) in sele_list['c'][typeIndex]" :key="key" class="tool_sele_btn"
              :class="{ btn_sele: number_list.indexOf(typeIndex + '-' + item) != -1 }" @click="set_number(item)">{{ '号码'
                +
                item }}<i class="el-icon-check"></i></div>
            <div class="btn" @click="set_number()">
              清除选择
            </div>
          </div>
        </div>
      </transition>
      <history-table :type="type" :cz="code" :head_list="render_head" @change="tab_change" :list="data"
        :sele_list="f_number_list"></history-table>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, DatePicker, Popover, Loading } from 'element-ui'
import moment from 'moment'
import Number from '@components/pc/Number'
import historyTable from '@components/pc/history-table'

export default {
  name: 'LotteryHistory',
  props: {
    code: String
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Number.name]: Number,
    historyTable
  },
  data() {
    return {
      per_page: 50,
      display: 'default',
      date: Date.now(),
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      data: [],
      loading: null,
      number_list: [],
      f_number_list: [],
      typeIndex: 0,
      tpl: 1,
      sele_list: {},
      render_head: [],
      type: '',
      is_number: true
    }
  },
  computed: {
    opencodeLength() {
      const [row] = this.data
      if (!row) return 0
      return row.opencode.split(',').length
    },
    hasSum() {
      const [row] = this.data
      if (!row) return false
      return Object.prototype.hasOwnProperty.call(row, 'sum')
    },
    sumTitle() {
      if (this.isAllSum) {
        return '总和'
      } else {
        return '冠亚军和'
      }
    },
    sumSubtitle() {
      if (this.isAllSum) {
        return '总和大小：“总和值”大于83时，为“大”；小于83时，为“小”；等于83时，为“和”;<br>总和单双：“总和值”为单数时，显示“单”；为双数时，显示“双”'
      } else {
        return '冠亚和大小：“冠亚和值”大于11时，为“大”；小于或等于11时，为“小”<br>冠亚和单双：“冠亚和值”为单数时，显示“单”；为双数时，显示“双”'
      }
    },
    loongTigerLength() {
      const [row] = this.tableData
      if (!row) return 0
      const { loongTiger } = row
      if (!loongTiger) return 0
      return loongTiger.length
    },
    isAllSum() {
      return this.opencodeLength === 3 || this.opencodeLength === 5 || this.opencodeLength === 8
    },
    tableData() {
      const arr = []
      // eslint-disable-next-line camelcase
      for (const { id, opentimestamp, expect, opencode, sum, loong_tiger, big_small, single_double } of this.data) {
        const open = opencode.split(',')
        const row = {
          id,
          opentimestamp,
          expect,
          open,
          sum,
          big_small,
          single_double
        }
        // eslint-disable-next-line camelcase
        if (loong_tiger) {
          // eslint-disable-next-line camelcase
          row.loongTiger = loong_tiger
        }
        arr.push(row)
      }
      return arr
    },
    displayTypes() {
      const arr = [{
        label: '显示号码',
        value: 'default'
      }]
      const [row] = this.data
      if (!row) return arr
      if (Object.prototype.hasOwnProperty.call(row, 'big_small')) {
        arr.push({
          label: '显示大小',
          value: 'size'
        })
      }
      if (Object.prototype.hasOwnProperty.call(row, 'single_double')) {
        arr.push({
          label: '显示单双',
          value: 'odevity'
        })
      }
      return arr
    }
  },
  watch: {
    '$route.path': {
      handler() {
        this.is_number = true
      },
      immediate: true
    },
    code: function (val, oldVal) {
      this.per_page = 50
      this.display = 'default'
      this.number_list = []
      this.f_number_list = []
      this.typeIndex = 0
      this.sele_list = {}
      this.tpl = 1
      this.data = []
      this.get_filter_list()
      this.requestGetHistory()
    },
    date: function (val, oldVal) {
      this.requestGetHistory()
    }
  },
  mounted() {
    this.display = 'default'
    this.get_filter_list()
    this.requestGetHistory()
  },
  methods: {
    page_change(value) {
      this.per_page = value
      this.requestGetHistory()
    },
    tab_change(val) {
      this.is_number = val == 0 ? true : false
    },
    get_filter_list() {
      const cz_obj = {
        'xyft': 1,
        'donggansaiche': 1,
        '168xyft': 1,
        'jisupk10': 1,
        'jisupk10168': 1,
        'azxy10': 1,
        'azxy10168': 1,
        'sbspeedy10': 1,
        'sgairship': 1,
        'azxy8': 2,
        'jisukl10': 2,
        'azxy8168': 2,
        'jisukl10168': 2,
        'gdklsf': 2,
        'donggan61': 3,
        'amlhc': 3,
        'twlhc': 3,
        'hk6': 3,
        'happy61': 3,
        'dongganshengxiao': 4,
        'azxy5': 5,
        'azxy5168': 5,
        'txffc': 5,
        'jisussc': 5,
        'jisussc168': 5,
        'dongganwuxing': 5,
        'sg5d': 5,
        'dongganpuke': 6,
        'donggansanbao': 7,
        'speedy3': 7,
        'sgquick3': 7,
        // 'pcdd': 0
      }
      const tpl_list = [
        {
          p: [],
          c: [
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
          ]
        },
        {
          p: [],
          c: [
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
          ]
        },
        {
          p: [],
          c: [
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49]
          ]
        },
        {
          p: [],
          c: [
            ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪']
          ]
        },
        {
          p: [],
          c: [
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
          ]
        },
        {
          p: [],
          c: [
            [
              'A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'
            ]
          ]
        },
        {
          p: [],
          c: [
            [1, 2, 3, 4, 5, 6]
          ]
        }
      ]
      this.tpl = cz_obj[this.code] || 1
      this.sele_list = tpl_list[this.tpl - 1]
    },
    set_number(val) {
      if (/[0-9|A-Z|\u4E00-\u9FA5]+/.test(val)) {
        const str = this.typeIndex + '-' + val
        const i = this.number_list.indexOf(str)
        if (i == -1) {
          this.number_list.push(str)
          if (this.tpl == 4) {
            this.f_number_list.push(this.sele_list['c'][0].indexOf(val) + 1)
          } else {
            this.f_number_list.push(val)
          }
        } else {
          this.number_list.splice(i, 1)
          this.f_number_list.splice(i, 1)
        }
      } else {
        this.f_number_list = []
        this.number_list = []
      }
    },
    render_circle(val) {
      let str = val
      if (val == '单' || val == '大' || val == '龙') {
        str = '<span style="background-color: #fa3e3e;width: 100%;height: 100%;border-radius: 50px;display: flex;align-items: center;justify-content: center;">' + val + '</span>'
      }
      if (val == '和') {
        str = '<span style="background-color: #FFC924;width: 100%;height: 100%;border-radius: 50px;display: flex;align-items: center;justify-content: center;">' + val + '</span>'
      }
      if (val == '双' || val == '小' || val == '虎') {
        str = '<span style="background-color: #07a6f8;width: 100%;height: 100%;border-radius: 50px;display: flex;align-items: center;justify-content: center;">' + val + '</span>'
      }
      return str
    },
    render(val) {
      let str = val
      if (val == '单' || val == '大' || val == '龙') {
        str = '<span style="color: #fa3e3e">' + val + '</span>'
      }
      if (val == '和') {
        str = '<span style="color: #FFC924">' + val + '</span>'
      }
      if (val == '双' || val == '小' || val == '虎') {
        str = '<span style="color: #07a6f8">' + val + '</span>'
      }
      return str
    },
    refresh() {
      this.requestGetHistory()
    },
    hideTableHeader({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 1) {
        return { display: 'none' }
      }
    },
    tableCellStyle({ row, column, rowIndex, columnIndex }) {
      const style = { color: '#FF7300' }
      if (
        (columnIndex === 4 && row.sum.sum_big_Small === '大') ||
        (columnIndex === 5 && row.sum.sum_single_double === '双')
      ) {
        return style
      }
      if (columnIndex >= 6 && columnIndex <= 10) {
        const i = columnIndex - 6
        const value = row.loongTiger[i]
        if (value === '龙') {
          return style
        }
      }
    },
    round_2(value) {
      return parseFloat(parseFloat(value).toFixed(2))
    },
    get_w(arr) {
      const w = document.getElementById('w_box').clientWidth
      const a = (typeof arr == 'object') ? [].concat(arr) : [].concat(this.render_head)
      let _w = 0
      let scale = 0
      for (let i in a) {
        if (/\d+/.test(a[i]['scale'])) {
          scale += this.round_2(a[i]['scale'])
        } else {
          _w += this.round_2(/\d+/.test(a[i]['width']) ? a[i]['width'] : 0)
        }
      }
      _w = w - _w
      const scale_w = this.round_2(_w / scale)
      for (let ii in a) {
        if (/\d+/.test(a[ii]['scale'])) {
          a[ii]['width'] = this.round_2(a[ii]['scale'] * scale_w)
        }
      }
      return a
    },
    get_type(name) {
      const obj = {
        'xyft': 0,
        'donggansaiche': 0,
        'jisupk10': 0,
        'azxy10': 0,
        'jisupk10168': 0,
        'azxy10168': 0,
        'sbspeedy10': 0,
        'sgairship': 0,
        'azxy8': 2,
        'jisukl10': 2,
        'azxy8168': 2,
        'jisukl10168': 2,
        'gdklsf': 2,
        'donggan61': 6,
        'amlhc': 6,
        'twlhc': 6,
        'hk6': 6,
        'happy61': 6,
        'dongganshengxiao': 5,
        'azxy5': 1,
        'azxy5168': 1,
        'txffc': 1,
        'jisussc': 1,
        'jisussc168': 1,
        'dongganwuxing': 1,
        'sg5d': 1,
        'dongganpuke': 3,
        'donggansanbao': 4,
        'speedy3': 4,
        'sgquick3': 4,
        'pcdd': 1
      }
      this.type = obj[name]
    },
    change_data() {
      if (this.type === 0) {
        this.render_head = [
          { name: '开奖时间', key: 'opentime', width: '', scale: 4.3 },
          { name: '开奖期数', key: 'expect', width: '', scale: 3 },
          { name: '详情', key: ['opencode', 'big_small', 'single_double'], list: ['号码', '大小', '单双'], width: '', scale: 10.6 },
          { name: '冠亚和', key: 'sum', width: '', scale: 2.8 },
          { name: '三四和', key: 'sum34', width: '', scale: 2.8 },
          { name: '五六和', key: 'sum56', width: '', scale: 2.8 },
          { name: '七八和', key: 'sum78', width: '', scale: 2.8 },
          { name: '九十和', key: 'sum90', width: '', scale: 2.8 },
          { name: '龙虎', key: 'loong_tiger', width: '', scale: 4.8 }
        ]
      }
      if (this.type === 1) {
        if (this.code == 'pcdd') {
          this.render_head = [
            { name: '开奖时间', key: 'opentime', width: '', scale: 1.2 },
            { name: '开奖期数', key: 'expect', width: '', scale: 1 },
            { name: '详情', key: ['opencode', 'big_small', 'single_double'], list: ['号码', '大小', '单双'], width: '', scale: 2 },
            { name: '总和', key: 'sum', width: '', scale: 2 }
          ]
        } else {
          this.render_head = [
            { name: '开奖时间', key: 'opentime', width: '', scale: 2 },
            { name: '开奖期数', key: 'expect', width: '', scale: 1.6 },
            { name: '详情', key: ['opencode', 'big_small', 'single_double'], list: ['号码', '大小', '单双'], width: '300' },
            { name: '总和', key: 'sum', width: '', scale: 3 },
            { name: '龙虎', key: 'loong_tiger', width: '', scale: 1 },
            { name: '前三', key: 'former_1', width: '', scale: 1 },
            { name: '中三', key: 'former_2', width: '', scale: 1 },
            { name: '后三', key: 'former_3', width: '', scale: 1 }
          ]
        }
      }
      if (this.type === 2) {
        this.render_head = [
          { name: '开奖时间', key: 'opentime', width: '', scale: 1.5 },
          { name: '开奖期数', key: 'expect', width: '', scale: 1 },
          { name: '详情', key: ['opencode', 'big_small', 'single_double', 'big_smalls', 'single_doubles'], list: ['号码', '大小', '单双', '尾大小', '合单双'], width: '420' },
          { name: '总和', key: 'sum', width: '', scale: 2 },
          { name: '龙虎', key: 'loong_tiger', width: '', scale: 2 }
        ]
      }
      if (this.type === 3) {
        this.render_head = [
          { name: '开奖时间', key: 'opentime', width: '', scale: 1.4 },
          { name: '开奖期数', key: 'expect', width: '', scale: 1.2 },
          { name: '详情', key: ['opencode', 'big_small', 'single_double', 'big_smalls', 'single_doubles'], list: ['点数', '大小', '单双', '尾大小', '合单双'], width: '400' },
          { name: '牌型', key: 'shape', width: '', scale: 1 },
          { name: '花色', key: 'decor', width: '', scale: 2.2 }
        ]
      }
      if (this.type === 4) {
        this.render_head = [
          { name: '开奖时间', key: 'opentime', width: '', scale: 1.2 },
          { name: '开奖期数', key: 'expect', width: '', scale: 1 },
          { name: '号码', key: 'opencode', width: '', scale: 1.4 },
          { name: '总和', key: 'sum', width: '', scale: 2 }
        ]
      }
      if (this.type === 5) {
        this.render_head = [
          { name: '开奖时间', key: 'opentime', width: '', scale: 1.3 },
          { name: '开奖期数', key: 'expect', width: '', scale: 1 },
          { name: '详情', key: ['opencode', 'animals', 'yin_yang', 'before_after'], list: ['生肖', '禽兽', '阴阳', '前后'], width: '330' },
          { name: '五行', key: 'five_shapes', width: '', scale: 2 },
          { name: '八卦', key: 'eight_trigrams', width: '', scale: 2 }
        ]
      }
      if (this.type === 6) {
        this.render_head = [
          { name: '开奖时间', key: 'opentime', width: '', scale: 1.4 },
          { name: '开奖期数', key: 'expect', width: '', scale: 1 },
          { name: '详情', key: ['opencode', 'big_small', 'single_double', 'big_smalls', 'single_doubles'], list: ['号码', '大小', '单双', '尾大小', '合单双'], width: '400' },
          { name: '生肖', key: 'zodiac', width: '', scale: 2 },
          { name: '总和', key: 'total', width: '', scale: 1 }
        ]
      }
      this.$nextTick(() => {
        this.render_head = [].concat(this.get_w())
      })
    },
    async requestGetHistory() {
      this.showTableLoading()
      await this.$api
        .historyOpen(this.code, this.per_page)
        .then((res) => {
          if (this.code === 'pcdd') {
            let arr = []
            for (let i in res.data) {
              const a = []
              const obj = res.data[i]
              a.push(obj['sum'])
              a.push(obj['sum_big_small'])
              a.push(obj['sum_single_double'])
              obj['sum'] = a
              arr.push(obj)
            }
            this.data = arr
          } else {
            this.data = res.data
          }
          this.get_type(this.code)
          this.change_data()
          this.hideTableLoading()
        })
        .catch((err) => {
          this.hideTableLoading()
          this.$message.error(err)
        })
    },
    showTableLoading() {
      if (this.loading) return
      this.loading = Loading.service({
        target: '#table',
        fullscreen: false,
        background: 'rgba(0, 0, 0, 0)'
      })
    },
    hideTableLoading() {
      this.$nextTick(() => {
        this.loading.close()
        this.loading = null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';

.lottery-history {
  >.header {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    >.title-box {
      display: flex;
      align-items: center;
      height: 49px;

      >.title {
        font-size: 20px;
        font-weight: 400;
        color: #222;
      }
    }

    >.search-box {
      display: flex;
      align-items: center;

      >.title {
        margin-right: 15px;
        font-size: 14px;
        color: #222;
      }

      >.icon-search {
        margin-left: 10px;
        width: 48px;
        height: 24px;
        display: block;
      }
    }
  }

  .lz_cont {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  .types {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 16px;
    background-color: #F9F9F9;
    display: flex;
    align-items: center;

    >li {
      cursor: pointer;
      position: relative;
      padding: 0 10px;
      font-size: 14px;
      font-weight: bold;
      color: #A1A1A1;
      min-width: 108px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-right: 1px solid #E3E3E5;
      border-bottom: 1px solid #E3E3E5;

      &.active {
        color: #FA3E3E;
        background-color: #fff;
        border-top: 2px solid #FA3E3E;
        border-bottom: none;
      }
    }

    .types_empty {
      flex: 1;
      border-right: none;
    }
  }

  .tool {
    margin-bottom: 14px;
    display: flex;
    align-items: flex-end;

    .tool_sele_btns {
      margin-bottom: 6px;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .tool_sele_span {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 68px;
        height: 24px;
        font-size: 14px;
      }

      .tool_sele_btn {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 68px;
        height: 24px;
        font-size: 14px;
        color: #959595;
        border: 1px solid #E3E3E5;
        border-radius: 4px;
        cursor: pointer;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        &:nth-child(16n) {
          margin-right: 0;
        }

        >i {
          display: none;
        }
      }

      .btn_sele {
        color: #FA3E3E;
        border: 1px solid #FA3E3E;
        position: relative;

        &::before {
          content: '';
          width: 0;
          height: 0;
          border-bottom: 20px solid #FA3E3E;
          border-left: 20px solid transparent;
          position: absolute;
          bottom: 0;
          right: 0;
          margin-bottom: -1px;
          margin-right: -1px;
        }

        >i {
          display: block;
          color: #fff;
          font-size: 12px;
          position: absolute;
          bottom: 0;
          right: 0;
          margin-bottom: -1px;
          margin-right: -1px;
        }
      }
    }

    .btn {
      margin-bottom: 10px;
      width: 86px;
      height: 24px;
      background-color: #F9F9F9;
      border: 1px solid #E3E3E6;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      line-height: 24px;
      color: #222;
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 0;

      >i {
        margin-right: 6px;
      }
    }
  }
}

.sum-tip {
  padding: 12px;

  >.title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
  }

  >.content {
    margin-top: 8px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
  }
}
</style>

<style lang="scss">
.transparent_class {
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.lottery-history>.table {
  margin-top: 20px;
}

.lottery-history>.table .table-header-cell {
  background-color: #f9f9f9;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}

.lottery-history>.table .table-cell {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  padding: 0;
  height: 40px;
}

.lottery-history>.table .table-row:hover>td,
.lottery-history>.table .table-row .current-row>td {
  background-color: rgba(255, 204, 170, 0.4);
}
</style>
