<template>
  <div class="table_history_box" id="histroy_table_box">
    <div class="table_head table_head_line">
      <div v-for="(item, key) in head_list" :key="key" :style="{ width: item.width ? (item.width + 'px') : '' }"
        :class="{ isometry: (!item.width && item.name != '号码') }">
        <div v-if="item.name != '详情'">{{ item.name }}</div>
        <div v-if="item.name == '详情'" class="table_bts">
          <div>
            <span v-for="(it, k) in item.list" :key="k" :class="t_k === k ? 'table_sele' : ''" @click="tab(k)">{{ it
              }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="table_cont_box">
      <div v-for="(data, pkey) in list" :key="pkey" class="table_item table_head_line">
        <div v-for="(item, key) in head_list" :key="key" :style="{ width: item.width ? (item.width + 'px') : '' }"
          :class="{ isometry: (!item.width && item.name != '号码') }">

          <div class="normal_box border_e_r"
            v-if="(item.name != '详情' && item.name != '号码' && typeof data[item.key] == 'string')"
            v-html="item.key == 'opentime' ? normal_time(data[item.key]) : render(data[item.key])">}</div>

          <div class="normal_box"
            v-if="(item.name != '详情' && item.name != '号码') && (typeof data[item.key] != 'string')">
            <span v-for="(it, k) in data[item.key]" :key="k" v-html="render(it, item.name)"></span>
          </div>

          <div class="normal_box border_e_r" v-if="item.name == '号码'">
            <number v-for="(number, key) in data[item.key].split(',')" :key="key" :value="number" :lottery-code="cz"
              :class="{ transparent_class: is_trans(number) }">{{ number }}</number>
          </div>

          <div class="special_box" v-if="item.name == '详情'">
            <div v-for="(sbt, sbk) in item.list" :key="sbk" v-show="t_k == sbk">
              <div v-if="sbt == '号码'" class="number_box">
                <div v-for="(number, key) in data[item.key[0]].split(',')" :key="number + key">
                  <number :value="number" :lottery-code="cz" :class="{ transparent_class: is_trans(number) }">{{ number
                    }}
                  </number>
                </div>
              </div>
              <div v-if="sbt == '点数'" class="number_box">
                <div v-for="(number, key) in data[item.key[0]].split(',')" :key="key">
                  <number :value="number" :lottery-code="cz" :class="{ transparent_class: is_trans(number) }">{{ number
                    }}
                  </number>
                </div>
              </div>
              <div v-if="sbt == '生肖'" class="number_box">
                <div v-for="(number, key) in data[item.key[0]].split(',')" :key="key">
                  <number :value="number" :lottery-code="cz" :class="{ transparent_class: is_trans(number) }">{{ number
                    }}
                  </number>
                </div>
              </div>
              <div v-if="sbt != '号码' && sbt != '点数' && sbt != '生肖'" class="special_other_box">
                <div v-for="(it, k) in data[item.key[t_k]]" :key="k">
                  <span v-html="render_circle(it, sbt)"></span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Number from '@components/pc/Number'

export default {
  props: {
    sele_list: {
      default: () => {
        return []
      }
    },
    cz: {
      default: ''
    },
    type: {
      default: ''
    },
    head_list: {
      default: () => {
        return []
      }
    },
    list: {
      default: () => {
        return []
      }
    }
  },
  components: {
    [Number.name]: Number
  },
  watch: {
    head_list: {
      handler() {
        this.t_k = 0
        this.empty_tip = '暂无数据'
      },
      deep: true
    }
  },
  data() {
    return {
      empty_tip: '',
      t_k: 0
    }
  },
  mounted() {

  },
  methods: {
    tab(k) {
      this.t_k = k
      this.$emit('change', k)
    },
    render_circle(val, type) {
      let str = val
      if (val == '单' || val == '大' || val == '龙' || val == '尾大' || val == '合单' || val == '禽' || val == '阴' || val == '前') {
        str = '<span class="tab_circle_box" style="background-color: #FF6161;">' + val + '</span>'
      }
      else if (val == '和') {
        if (this.cz == 'donggan61' && this.t_k == 3) {
          str = '<span class="tab_circle_box" style="background-color: #FF9900;min-width: 40.44px;">' + val + '</span>'
        } else {
          str = '<span class="tab_circle_box" style="background-color: #FF9900;">' + val + '</span>'
        }
      }
      else if (val == '双' || val == '小' || val == '虎' || val == '尾小' || val == '合双' || val == '兽' || val == '阳' || val == '后') {
        str = '<span class="tab_circle_box" style="background-color: #07A6F8;">' + val + '</span>'
      }
      else {
        str = '<span class="tab_circle_box" style="background-color: #969697;">' + val + '</span>'
      }
      return str
    },
    is_trans(val) {
      if (this.sele_list.length == 0) return false
      if (this.cz == 'dongganpuke') {
        let number = parseInt(val) % 13
        number = number == 1 ? 'A' : (number == 11 ? 'J' : (number == 12 ? 'Q' : (number == 0 ? 'K' : number)))
        number += ''
        return !(this.sele_list.indexOf(number) != -1)
      }
      return !(this.sele_list.indexOf(parseInt(val)) != -1)
    },
    normal_time(time) {
      return time
      return moment(time).format('HH:mm:ss')
    },
    render(v, type) {
      if (v == '和') {
        return '<span style="color: rgb(242, 242, 48)">' + v + '</span>'
      }
      if (this.type == 3) {
        const obj = {
          '黑桃': '/opencode/poker/ht.png',
          '红桃': '/opencode/poker/hx.png',
          '梅花': '/opencode/poker/mh.png',
          '方块': '/opencode/poker/fk.png'
        }
        const str = v.toString().replace(/牌型-/, '')
        if (obj[str]) {
          return '<img src="' + obj[str] + '"/>'
        }
        if (/牌型-/.test(v)) {
          return str
        }
      }
      if (this.type == 5) {
        const obj = {
          '鼠': '/opencode/zodiac/1.png',
          '牛': '/opencode/zodiac/2.png',
          '虎': '/opencode/zodiac/3.png',
          '兔': '/opencode/zodiac/4.png',
          '龙': '/opencode/zodiac/5.png',
          '蛇': '/opencode/zodiac/6.png',
          '马': '/opencode/zodiac/7.png',
          '羊': '/opencode/zodiac/8.png',
          '猴': '/opencode/zodiac/9.png',
          '鸡': '/opencode/zodiac/10.png',
          '狗': '/opencode/zodiac/11.png',
          '猪': '/opencode/zodiac/12.png'
        }
        if (obj[v]) {
          return '<img style="width: 34px;height: auto;" src="' + obj[v] + '"/>'
        } else {
          if (type == '五行') {
            const d = v
            let s = ''
            if (d == '阴' || d == '禽' || d == '前' || d == '火' || d == '离') {
              s = '<span style="color: #FA3E3E">' + d + '</span>'
            }
            if (d == '阳' || d == '兽' || d == '后' || d == '水' || d == '坎') {
              s = '<span style="color: #07A6F8">' + d + '</span>'
            }
            if (d == '金' || d == '乾' || d == '兑') {
              s = '<span style="color: #FFC924">' + d + '</span>'
            }
            if (d == '木' || d == '震' || d == '巽') {
              s = '<span style="color: #FFC924">' + d + '</span>'
            }
            if (d == '土' || d == '坤' || d == '艮') {
              s = '<span style="color: #D09C2E">' + d + '</span>'
            }
            return s
          }
          return v
        }
      }
      if (this.type == 6) {
        if (v == '大' || v == '单' || v == '尾大' || v == '合单') {
          return '<span style="color: #fa3e3e">' + v + '</span>'
        }
        if (v == '小' || v == '双' || v == '尾小' || v == '合双') {
          return '<span style="color: #07a6f8">' + v + '</span>'
        }
        return v
      }
      if (v == '大' || v == '单' || v == '龙' || v == '尾大' || v == '合单') {
        return '<span style="color: #fa3e3e">' + v + '</span>'
      }
      if (v == '小' || v == '双' || v == '虎' || v == '尾小' || v == '合双') {
        return '<span style="color: #07a6f8">' + v + '</span>'
      }
      return v
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin cont($g) {
  .table_history_box {
    margin-bottom: 20px * $g;
    width: 100%;
    height: auto;
    border-top: 1px solid #e3e4e5;
    display: flex;
    flex-direction: column;

    .table_cont_box {
      width: 100%;
      height: auto;
      overflow: hidden;
    }

    .table_head:first-child {
      flex-shrink: 0;
      height: 42px * $g;
    }

    .table_head,
    .table_item {
      height: 42px * $g;
      border-left: 1px solid #e3e4e5;
      display: flex;
    }

    .table_head {
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      border-bottom: 1px solid #e3e4e5;
      font-size: 16px * $g;
      background-color: #F9F9F9;

      >div {
        width: auto;
        height: 100%;
        box-sizing: border-box;
        display: flex;

        >div {
          width: 100%;
          height: 100%;
          border-right: 1px solid #e3e4e5;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .table_bts {
          font-size: 16px * $g;
          cursor: pointer;

          >div {
            width: auto;
            height: 28px * $g;
            background-color: #fff;
            border-radius: 6px;
            overflow: hidden;
            display: flex;

            >span {
              width: 84px * $g;
              line-height: 28px * $g;
              text-align: center;
              border-right: 1px solid #e3e4e5;

              &:last-child {
                border-right: none;
              }

              &:hover {
                background-color: #999;
                color: #fff;
              }
            }
          }
        }
      }
    }

    .table_head_line {
      border-left: 1px solid #e3e4e5 !important;
    }

    .table_item {
      font-size: 16px * $g;
      border-bottom: 1px solid #e3e4e5;

      >div {
        width: auto;
        height: 100%;
        box-sizing: border-box;
        display: flex;

        // >div {
        //   border-right: 1px solid #e3e4e5;
        // }
        .normal_box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          >span {
            flex: 1;
            height: 100%;
            border-right: 1px solid #e3e4e5;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .special_box {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          >div {
            width: 100%;
            height: 100%;
          }

          .number_box {
            width: 100%;
            height: 100%;
            display: flex;

            >div {
              flex: 1;
              border-right: 1px solid #e3e4e5;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .special_other_box {
            width: 100%;
            height: 100%;
            display: flex;

            >div {
              flex: 1;
              border-right: 1px solid #e3e4e5;
              display: flex;
              align-items: center;
              justify-content: center;

              >span {
                margin-right: 3px;

                &:last-child {
                  border-right: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .page_box {
    margin-top: 14px * $g;
  }

  .limit_0 {
    width: 336px * $g !important;
  }

  .limit_1 {
    width: 190px * $g !important;
  }

  .no_class {
    font-weight: bold;
  }

  .blue_class {
    font-weight: bold;
    color: #07a6f8;
  }

  .red_class {
    font-weight: bold;
    color: #fa3e3e;
  }

  .table_sele {
    background-color: #999;
    color: #fff;
  }

  .border_r_line {
    border-right: 1px solid #e3e4e5;

    &:last-child {
      border-right: none;
    }
  }

  .border_e_r {
    border-right: 1px solid #e3e4e5;
  }

  .border_e {
    border: 1px solid #e3e4e5;
    border-top: none;
  }

  .isometry {
    flex: 1;
  }
}

;
@include cont(1280 / 1440);
</style>

<style lang="scss">
.tab_circle_box {
  padding: 0 6px;
  min-width: 28px;
  height: 28px;
  color: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#histroy_table_box .table_item:nth-child(2n) {
  background-color: #f7f7f7;
}
</style>